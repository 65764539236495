
const Users = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.5}
    height={27.907}
    {...props}
  >
    <g fill="#0a60b0">
      <path
        d="M23.5 27.909h-2.828a8.8 8.8 0 0 0-8.923-8.65 8.8 8.8 0 0 0-8.921 8.65H0a11.629 11.629 0 0 1 11.751-11.48A11.629 11.629 0 0 1 23.5 27.909Z"
        data-name="Trazado 415"
      />
      <path
        d="M11.751 15.51a7.755 7.755 0 1 1 7.76-7.755 7.765 7.765 0 0 1-7.76 7.755Zm0-12.682a4.927 4.927 0 1 0 4.929 4.927 4.932 4.932 0 0 0-4.929-4.927Z"
        data-name="Trazado 416"
      />
    </g>
  </svg>
)
export default Users

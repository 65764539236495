import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/resetPassword.scss'; 

const endpoint = process.env.REACT_APP_ENDPOINT;

const ResetPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [apiError, setApiError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        setApiError(null);
        try {
            const response = await axios.post(`${endpoint}reset-password`, {
                email,
                token,
                password: data.password,
                password_confirmation: data.password
            });

            if (response.status === 200) {
                console.log(response)
                console.log(token)
                setApiError(response.data.error)
                //navigate('/'); // Redirige al usuario a la página de inicio de sesión después de restablecer la contraseña
            }
        } catch (error) {
            console.log(error)
            setApiError(error.response ? error.response.data.error : 'Error desconocido');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="reset-password">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Restablecer Contraseña</h2>

                <label htmlFor="password">Nueva Contraseña</label>
                <input
                    type="password"
                    name="password"
                    {...register('password', { required: true, minLength: 8 })}
                />
                {errors.password && <span>La contraseña es requerida y debe tener al menos 8 caracteres</span>}

                <label htmlFor="password_confirmation">Confirmar Contraseña</label>
                <input
                    type="password"
                    name="password_confirmation"
                    {...register('password_confirmation', {
                        required: true,
                        validate: value => value === watch('password') || 'Las contraseñas no coinciden'
                    })}
                />
                {errors.password_confirmation && <span>{errors.password_confirmation.message}</span>}

                {apiError && <div className="error-message">{apiError}</div>}

                <button type="submit" disabled={isSubmitting}>Restablecer Contraseña</button>
            </form>
        </div>
    );
};

export default ResetPassword;

const Docs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26.209}
    height={35.004}
    {...props}
  >
    <g fill="#0a60b0" stroke="#0a60b0" strokeWidth={0.5}>
      <path
        d="M3.633 5.662h1.353a.677.677 0 0 0 0-1.353H3.633a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 418"
      />
      <path
        d="M7.692 5.662h6.765a.677.677 0 1 0 0-1.353H7.692a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 419"
      />
      <path
        d="M3.633 9.722h1.353a.677.677 0 0 0 0-1.353H3.633a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 420"
      />
      <path
        d="M7.692 9.722H19.87a.677.677 0 1 0 0-1.353H7.692a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 421"
      />
      <path
        d="M3.633 13.781h1.353a.677.677 0 0 0 0-1.353H3.633a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 422"
      />
      <path
        d="M7.692 13.781H19.87a.677.677 0 1 0 0-1.353H7.692a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 423"
      />
      <path
        d="M3.633 17.84h1.353a.677.677 0 1 0 0-1.353H3.633a.677.677 0 1 0 0 1.353Z"
        data-name="Trazado 424"
      />
      <path
        d="M7.692 17.84H19.87a.677.677 0 1 0 0-1.353H7.692a.677.677 0 0 0 0 1.353Z"
        data-name="Trazado 425"
      />
      <path
        d="M19.87 23.59h-7.442a.677.677 0 1 0 0 1.353h7.442a.677.677 0 1 0 0-1.353Z"
        data-name="Trazado 426"
      />
      <path
        d="M6.677 27.988a3.721 3.721 0 1 0-3.721-3.721 3.725 3.725 0 0 0 3.721 3.721Zm0-6.089a2.368 2.368 0 1 1-2.368 2.368 2.371 2.371 0 0 1 2.368-2.368Z"
        data-name="Trazado 427"
      />
      <path
        d="M25.282 7.692H23.25a14.178 14.178 0 0 0-.01-1.482.683.683 0 0 0-.185-.349C23.05 5.85 17.64.436 17.557.377a.668.668 0 0 0-.371-.127H.926A.677.677 0 0 0 .25.927v30.444a.677.677 0 0 0 .677.677h2.03v2.03a.677.677 0 0 0 .677.677h21.648a.677.677 0 0 0 .677-.677V8.369a.677.677 0 0 0-.677-.677ZM17.84 2.56l3.1 3.1h-3.1ZM1.603 1.603h14.884v4.736a.677.677 0 0 0 .677.677H21.9v23.679H1.6Zm23 31.8h-20.3V32.05h18.273a.677.677 0 0 0 .674-.679V9.05h1.353Z"
        data-name="Trazado 428"
      />
    </g>
  </svg>
)
export default Docs

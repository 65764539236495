const Companies = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={30} height={29.487} {...props}>
    <g fill="#0A60B0" stroke="#fafafa" strokeWidth={0.5}>
      <path
        d="M26.346 3.749 14.891.362a2.326 2.326 0 0 0-2.974 2.223v5.093a.75.75 0 0 0 1.5 0V2.585a.82.82 0 0 1 1.049-.784l11.455 3.386a3.27 3.27 0 0 1 2.329 3.117v16.183a3.254 3.254 0 0 1-3.25 3.25h-9.25V15.108a4.756 4.756 0 0 0-4.75-4.75H5a4.756 4.756 0 0 0-4.75 4.75v9.379A4.756 4.756 0 0 0 5 29.237h20a4.756 4.756 0 0 0 4.75-4.75V8.304a4.777 4.777 0 0 0-3.404-4.555ZM14.25 27.737h-2.942v-3.522a3.308 3.308 0 0 0-6.616 0v.945a.75.75 0 0 0 1.5 0v-.945a1.808 1.808 0 0 1 3.616 0v3.522H5a3.254 3.254 0 0 1-3.25-3.25v-9.379A3.254 3.254 0 0 1 5 11.858h6a3.254 3.254 0 0 1 3.25 3.25Z"
        data-name="Trazado 381"
      />
      <path
        d="M4.893 13.867h-.606a.75.75 0 0 0 0 1.5h.606a.75.75 0 0 0 0-1.5Z"
        data-name="Trazado 382"
      />
      <path
        d="M8.3 13.867c-.593-.033-1.321-.027-1.35.75.029.777.756.783 1.35.75a.75.75 0 0 0 0-1.5Z"
        data-name="Trazado 383"
      />
      <path
        d="M11.713 13.867h-.606a.75.75 0 0 0 0 1.5h.606a.75.75 0 0 0 0-1.5Z"
        data-name="Trazado 384"
      />
      <path
        d="M4.893 17.397h-.606a.75.75 0 0 0 0 1.5h.606a.75.75 0 0 0 0-1.5Z"
        data-name="Trazado 385"
      />
      <path
        d="M8.3 17.397c-.593-.033-1.321-.027-1.35.75.029.777.757.783 1.35.75a.75.75 0 0 0 0-1.5Z"
        data-name="Trazado 386"
      />
      <path
        d="M11.713 17.397h-.606a.75.75 0 0 0 0 1.5h.606a.75.75 0 0 0 0-1.5Z"
        data-name="Trazado 387"
      />
      <path
        d="M18.893 10.149a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 388"
      />
      <path
        d="M23.05 9.4c-.029-.777-.757-.783-1.35-.75a.75.75 0 0 0 0 1.5c.593.033 1.321.027 1.35-.75Z"
        data-name="Trazado 389"
      />
      <path
        d="M25.713 10.149a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 390"
      />
      <path
        d="M18.287 14.792h.606a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 391"
      />
      <path
        d="M21.7 14.792c.593.033 1.321.027 1.35-.75-.029-.777-.757-.783-1.35-.75a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 392"
      />
      <path
        d="M25.107 14.792h.606a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 393"
      />
      <path
        d="M18.287 19.437h.606a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 394"
      />
      <path
        d="M21.7 19.437c.593.033 1.321.027 1.35-.75-.029-.777-.756-.783-1.35-.75a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 395"
      />
      <path
        d="M25.107 19.437h.606a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 396"
      />
      <path
        d="M18.287 24.084h.606a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 397"
      />
      <path
        d="M21.7 24.084c.593.033 1.321.027 1.35-.75-.029-.777-.757-.783-1.35-.75a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 398"
      />
      <path
        d="M25.107 24.084h.606a.75.75 0 0 0 0-1.5h-.606a.75.75 0 0 0 0 1.5Z"
        data-name="Trazado 399"
      />
    </g>
  </svg>
)
export default Companies


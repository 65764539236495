import '../styles/menu.scss';
import Companies from '../assets/companies';
import Users from '../assets/users';
import Docs from '../assets/docs';
import { useLocation } from 'react-router-dom';

const Menu = (selected)=>{
    const location = useLocation();

    return (
      <div className='menu'>
        <a href="/admin" className={location.pathname === '/admin' ? '-active' : ''}>
          <Companies /> Empresas
        </a>
        <a href="/admin/collaborators" className={location.pathname === '/admin/collaborators' ? '-active' : ''}>
          <Users /> Colaboradores
        </a>
        <a href="/admin/docs" className={location.pathname === '/admin/docs' ? '-active' : ''}>
          <Docs /> Documentos
        </a>
      </div>
    );
}

export default Menu;
import React, { useState, useContext, createContext } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [user, setUser] = useState(localStorage.getItem('user'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [role, setRole] = useState(localStorage.getItem('role'));

  const signin = (newUser,newRole, newToken, callback) => {
    setUser(newUser);
    setToken(newToken);
    setRole(newRole);
    localStorage.setItem('user', newUser);
    localStorage.setItem('token', newToken);
    localStorage.setItem('role', newRole);
    callback();
  };

  const signout = (callback) => {
    setUser(null);
    setToken(null);
    setRole(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    callback();
  };

  const value = { user, token, role, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
        className='navbar-signout'
      >
        
      </button>
    </p>
  );
}

function RequireAuth({ children, requiredRole }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (requiredRole === 'admin' && auth.role != 0) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (requiredRole === 'super' && auth.role != 1) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export { AuthProvider, useAuth, AuthStatus, RequireAuth };

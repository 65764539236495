import './App.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider, RequireAuth } from './auth';

import Login from './components/login';
import ResetPassword from './components/resetPassword';
import AdminCollaborators from './components/collaborators';
import AdminCompanies from './components/admin';
import Super from './components/super';




function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/restablecer" element={<ResetPassword />} />
            <Route
              path="/admin"
              element={
                <RequireAuth requiredRole="admin" children={AdminCompanies}>
                  <AdminCompanies />
                </RequireAuth>
              }
            />
            <Route
              path='/admin/collaborators'
              element={
                <RequireAuth requiredRole="admin" children={AdminCollaborators}>
                  <AdminCollaborators />
                </RequireAuth>
              }
            />
            <Route
              path="/super"
              element={
                <RequireAuth requiredRole="super" children={Super}>
                  <Super />
                </RequireAuth>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;

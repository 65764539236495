import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthStatus, useAuth } from '../auth';
import { DataGrid } from '@mui/x-data-grid';
import '../styles/collaborators.scss';

import { useForm } from "react-hook-form";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './navbar';
import Menu   from './menu';
import { json } from 'react-router-dom';

const endpoint = process.env.REACT_APP_ENDPOINT;

const AdminCollaborators = ()=>{
    const status = AuthStatus();
    const { token } = useAuth();

    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});

    const [collaborators, setCollaborators] = useState([]);
    const [companyCollaborators, setCompanyCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const [mode, setMode] = useState('add');
    const { register, handleSubmit, reset, setValue } = useForm();
    const onSubmit = async data => { 
        document.querySelector('input[type="submit"]').classList.add('-disable')

        if (Array.isArray(data.dias_laborales)) {
            data.dias_laborales = JSON.stringify(data.dias_laborales)
        }
        if(data.company_id){
            data.company_id = selectedCompany.id
        }

        if(mode == 'add'){
            console.log("Agregando...")
            try {
                const response = await axios.post(`${endpoint}admin/collaborators`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if(companyCollaborators.length > 0){
                    setCompanyCollaborators([...companyCollaborators, response.data])
                }else{
                    setCompanyCollaborators([response.data])
                }

               

                toast.success("Colaborador agregado correctamente");
                document.getElementById('addForm').click();
            } catch (error) {
                console.log(error)
                
                const errors = error.response.data.errors;
                if (typeof errors === 'object') {
                    const errorMessages = Object.entries(errors)
                        .flatMap(([field, messages]) =>
                            messages.map(message => `${field}: ${message}`)
                        )
                        .join('\n');
                
                    toast.error("Errores al crear:\n" + errorMessages);
                } else {
                    toast.error("Error al crear: " + error.response.data.error);
                }

                document.getElementById('addForm').click();
            } 
        }else{
            try {
                const response = await axios.put(`${endpoint}admin/collaborators/${selectedCollaborator.id}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                	
                let updatedCollaborators = []
                collaborators.map(collaborator=>{
                    if(collaborator.id == selectedCollaborator.id){
                        updatedCollaborators.push(response.data)
                        console.log(response.data)
                    }else{
                        updatedCollaborators.push(collaborator)
                    }
                });
                setCompanyCollaborators(updatedCollaborators.filter(collaborator=>collaborator.company_id == selectedCompany.id))
                setCollaborators(updatedCollaborators)
                console.log(updatedCollaborators)
                

                toast.success("Colaborador actualizado correctamente");
                document.getElementById('addForm').click();
            } catch (error) {
                console.log(error)
                
                const errors = error.response.data.errors;
                if (typeof errors === 'object') {
                    const errorMessages = Object.entries(errors)
                        .flatMap(([field, messages]) =>
                            messages.map(message => `${field}: ${message}`)
                        )
                        .join('\n');
                
                    toast.error("Errores al actualizar:\n" + errorMessages);
                } else {
                    toast.error("Error al actualizar: " + error.response.data.error);
                }

                document.getElementById('addForm').click();
            } 
           
        }
        document.querySelector('input[type="submit"]').classList.remove('-disable')
    };

    const handleDelete = async (e) =>{
        e.target.classList.add('-disable')

        try {
            await axios.delete(`${endpoint}admin/collaborators/${selectedCollaborator.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchCollaborators()
            document.getElementById('view').click()
            setSelectedCollaborator({});
            toast.success("Colaborador eliminado");
        } catch (error) {
            
            toast.error("Error al eliminar el colaborador");
        }

        e.target.classList.remove('-disable')
    }

    const formatTime = (timeString) => {
        if(timeString !=null){
            const timeParts = timeString.split(":");
            console.log( `${timeParts[0]}:${timeParts[1]}`)
            return `${timeParts[0]}:${timeParts[1]}`; // Solo HH:mm
        }else{
            return ''
        }
       
    };

    const formatFecha = (fechaString) => {
        if(fechaString!=null){
            const [year, month, day] = fechaString.split('-');
            return `${day}/${month}/${year}`;
        }else
            return '-';
       
    };


    const setForm = ()=>{
        setValue('nombre',selectedCollaborator.nombre)
        setValue('apellidos',selectedCollaborator.apellidos)
        setValue('telefono', selectedCollaborator.telefono)
        setValue('email', selectedCollaborator.email)
        setValue('sexo', selectedCollaborator.sexo)
        setValue('estado_civil', selectedCollaborator.estado_civil)
        setValue('nacionalidad', selectedCollaborator.nacionalidad)
        setValue('dias_laborales', selectedCollaborator.dias_laborales)
        let formated = formatTime(selectedCollaborator.hora_entrada)
        setValue('hora_entrada', formated)
        formated = formatTime(selectedCollaborator.hora_salida)
        setValue('hora_salida', formated)
        setValue('curp', selectedCollaborator.curp)
        setValue('nss', selectedCollaborator.nss)
        setValue('rfc',selectedCollaborator.rfc)
        setValue('fecha_nacimiento', selectedCollaborator.fecha_nacimiento)
        setValue('alta', selectedCollaborator.alta)
        setValue('baja', selectedCollaborator.baja)
        setValue('puesto', selectedCollaborator.puesto)
        setValue('turno', selectedCollaborator.turno)
    }

    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${endpoint}admin/companies`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCompanies(response.data);
            if(response.data.length > 0)
                setSelectedCompany(response.data[0])
        } catch (error) {
            toast.error('Error al obtener la lista de companias')
        }
    };
    const fetchCollaborators = async () => {
        try {
            const response = await axios.get(`${endpoint}admin/collaborators`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCollaborators(response.data);
            if(selectedCompany.id)
                setCompanyCollaborators(collaborators.filter(collaborator=>collaborator.company_id === selectedCompany.id))
            
            //console.log(response.data)
        } catch (error) {
            toast.error('Error al obtener la lista de colaboradores')
        }
    };

    useEffect(() => {
        fetchCompanies();
        fetchCollaborators();
    }, [token]);
    
    useEffect(() => {
        setCompanyCollaborators(collaborators.filter(collaborator=>collaborator.company_id == selectedCompany.id))
    }, [selectedCompany]);

    const filteredCollaborators = companyCollaborators.filter(collaborator =>
        collaborator.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        collaborator.apellidos.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = [
        
        { field: 'nombre', headerName: 'Nombre', width: 200 },
        { field: 'apellidos', headerName: 'Apellidos', width: 200 },
        { field: 'email', headerName: 'Correo electronico', width: 200 },
        { field: 'telefono', headerName: 'Teléfono', width: 200 }
               
    ];

    return (
        <div className="collaborators">
            <NavBar auth={status} companies={companies} selected={selectedCompany} set={setSelectedCompany}/>
            <div className="collaborators-row">
                <Menu />

                <div className='collaborators-wrapper'>
                    <ToastContainer />
                    <h2>Colaboradores</h2>

                    <div className="collaborators-controls">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar colaborador..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button className="btn btn-primary me-2 btn-rounded" id='addForm' type="button" data-bs-toggle="offcanvas" data-bs-target="#formOffcanvas" aria-controls="Form offcanvas" onClick={()=>{setSelectedCollaborator({id_company: selectedCompany.id}); reset(); setMode('add'); }}>
                            Agregar colaborador
                        </button>
                        

                        <button className="btn btn-primary me-2" id='view' type="button" data-bs-toggle="offcanvas" data-bs-target="#viewOffcanvas" aria-controls="View offcanvas" onClick={()=>setMode('update')}>
                            Ver
                        </button>
                       
                    </div>

                    <div style={{ height: '80vh', width: '100%' }} className='collaborators-table'>
                        <DataGrid
                            rows={filteredCollaborators}
                            columns={columns}
                            sx={{ borderColor: 'white' }}
                            pageSize={5}
                            onRowDoubleClick={()=>{document.getElementById('view').click(); setForm();}}
                            onRowClick={(row) => {setSelectedCollaborator(row.row); setForm(); document.getElementById('view').click() }}
                        />
                    </div>

                    {/* Vista */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="viewOffcanvas" aria-labelledby="viewOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="viewCompanyOffcanvasLabel">Detalles</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">

                            <div className="c-input">
                                <label htmlFor="name">Nombre</label>
                                <p>{selectedCollaborator.nombre}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="name">Apellidos</label>
                                <p>{selectedCollaborator.apellidos}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="phone">Teléfono</label>
                                <p>{selectedCollaborator.telefono}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="email">Correo electrónico</label>
                                <p>{selectedCollaborator.email}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="sexo">Sexo</label>
                                <p>{selectedCollaborator.sexo == 'masculino' ? 'Masculino' : (selectedCollaborator.sexo == 'femenino' ? 'Femenino' : 'Otro')}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="nacionalidad">Nacionalidad</label>
                                <p>{selectedCollaborator.nacionalidad}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="estado_civil">Estado Civil</label>
                                
                                <p>{selectedCollaborator.estado_civil}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="curp">CURP</label>
                                <p>{selectedCollaborator.curp}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="nss">NSS</label>
                                <p>{selectedCollaborator.nss}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="rfc">RFC</label>
                                <p>{selectedCollaborator.rfc}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="birth">Fecha de Nacimiento</label>
                                <p>{formatFecha( selectedCollaborator.fecha_nacimiento)}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="birth">Fecha de Alta</label>
                                <p>{formatFecha(selectedCollaborator.alta)}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="baja">Fecha de Baja</label>
                                <p>{formatFecha(selectedCollaborator.baja)}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="job">Puesto</label>
                                <p>{selectedCollaborator.puesto}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="turno">Turno</label>
                                <p>{selectedCollaborator.turno}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="">Dias laborales</label>
                                <p>{ selectedCollaborator.dias_laborales ? JSON.parse( selectedCollaborator.dias_laborales).join(', ')  :  ''}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="hora_entrada">Hora entrada</label>
                                <p>{selectedCollaborator.hora_entrada}</p>
                            </div>

                            <div className="c-input">
                                <label htmlFor="hora_salida">Hora salida</label>
                                <p>{selectedCollaborator.hora_salida}</p>
                            </div>
                            
                            <hr/>

                            <strong>Acciones:</strong>
                            <span>
                                <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#confirmModal">
                                    Borrar
                                </button>
                                <button className="btn btn-edit" id='updateForm' type="button" data-bs-toggle="offcanvas" data-bs-target="#formOffcanvas" aria-controls="Form offcanvas" onClick={()=>{setMode('update'); setForm()}}>
                                    Editar
                                </button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Close">Cancelar</button> 

                            </span>

                                                  
                                
                        </div>
                    </div>

                    {/* Formulario */}
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="formOffcanvas" aria-labelledby="formOffcanvasLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="createCompanyOffcanvasLabel">{mode == 'add' ? 'Agregar colaborador': 'Modificar colaborador'}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <input type='hidden' {...register("company_id")} value={selectedCompany ?  selectedCompany.id : 0} />
                                
                                <div className="c-input">
                                    <label htmlFor="name">Nombre</label>
                                    <input
                                        id='name'
                                        name='name'
                                        defaultValue={selectedCollaborator.nombre || ''}
                                        {...register("nombre", { required: "Agregar un nombre" })} // custom message
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="name">Apellidos</label>
                                    <input
                                        id='apellidos'
                                        name='apellidos'
                                        defaultValue={selectedCollaborator.apellidos || ''}
                                        {...register("apellidos", { required: "Agregar apellidos" })} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="phone">Teléfono</label>
                                    <input
                                        id='phone'
                                        name='phone'
                                        type='tel'
                                        defaultValue={selectedCollaborator.telefono || ''}
                                        minLength='10'
                                        {...register("telefono", { required: "Agregar télefono válido", minLength: {value: 10, message: 'Min 10'} })} // custom message
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="email">Correo electrónico</label>
                                    <input
                                        id='email'
                                        name='email'
                                        type='email'
                                        defaultValue={selectedCollaborator.email || ''}
                                        {...register("email")} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="sexo">Sexo</label>
                                    <select name="sexo" id="sexo" {...register('sexo',{required: "Especificar sexo"})} value={selectedCollaborator.sexo || ''} onChange={(e)=>{console.log(selectedCollaborator);setSelectedCollaborator({...selectedCollaborator, sexo: e.target.value }) }}>
                                        <option value="">Seleccionar</option>
                                        <option value="masculino">Masculino</option>
                                        <option value="femenino">Femenino</option>
                                        <option value="otro">Otro</option>
                                    </select>
                                </div>

                                <div className="c-input">
                                    <label htmlFor="nacionalidad">Nacionalidad</label>
                                    <input
                                        id='nacionalidad'
                                        name='nacionalidad'
                                        defaultValue={selectedCollaborator.nacionalidad || ''}
                                        {...register("nacionalidad",{required: 'Especificar nacionalidad'})} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="estado_civil">Estado Civil</label>
                                   
                                    <select 
                                        name="estado_civil" 
                                        id="estado_civil" 
                                        {...register('estado_civil',{required: "Seleccione el estado civil"})} 
                                        value={selectedCollaborator.estado_civil || ''}
                                        onChange={(e)=>{setSelectedCollaborator((prev) => ({...prev, estado_civil: e.target.value })); }}
                                        
                                    >
                                        <option value="">Seleccionar</option>
                                        <option value="soltero">Soltero/a</option>
                                        <option value="casado">Casado/a</option>
                                        <option value="divorciado">Divorciado/a</option>
                                        <option value="separado">Separado/a en proceso judicial</option>
                                        <option value="viudo">Viudo/a</option>
                                        <option value="concubinato">Concubinato</option>
                                    </select>
                                </div>

                                <div className="c-input">
                                    <label htmlFor="curp">CURP</label>
                                    <input
                                        id='curp'
                                        name='curp'
                                        maxLength={18}
                                        defaultValue={selectedCollaborator.curp || ''}
                                        {...register("curp", { required: "Agregar CURP", minLength:{value:18, message:'CURP Incompleto'}, maxLength:{value:18,message:'CURP Desmasiado largo'} } )} // custom message
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="nss">NSS</label>
                                    <input
                                        id='nss'
                                        name='nss'
                                        maxLength={11}
                                        defaultValue={selectedCollaborator.nss || ''}
                                        {...register("nss", { required: "Agregar un NSS", minLength:{value:11, message:'NSS Incompleto'} })}
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="rfc">RFC</label>
                                    <input
                                        id='rfc'
                                        name='rfc'
                                        maxLength={12}
                                        minLength={12}
                                        defaultValue={selectedCollaborator.rfc || ''}
                                        {...register("rfc", { required: "Agregar un RFC", minLength:{value:12, message:'RFC Incompleto'} })}
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="birth">Fecha de Nacimiento</label>
                                    <input
                                        id='birth'
                                        name='birth'
                                        type='date'
                                        defaultValue={selectedCollaborator.fecha_nacimiento || ''}
                                        {...register("fecha_nacimiento", { required: "Agregar una fecha" })} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="birth">Fecha de Alta</label>
                                    <input
                                        id='alta'
                                        name='alta'
                                        type='date'
                                        defaultValue={selectedCollaborator.alta || ''}
                                        {...register("alta", { required: "Agregar una fecha" })} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="baja">Fecha de Baja</label>
                                    <input
                                        id='baja'
                                        name='baja'
                                        type='date'
                                        defaultValue={selectedCollaborator.baja || ''}
                                        {...register("baja")} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="job">Puesto</label>
                                    <input
                                        id='job'
                                        name='job'
                                        defaultValue={selectedCollaborator.puesto || ''}
                                        {...register("puesto",{required: 'Especifique el puesto'})} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="turno">Turno</label>
                                    <input
                                        id='turno'
                                        name='turno'
                                        defaultValue={selectedCollaborator.turno || ''}
                                        {...register("turno",{required: 'Especifique el turno'})} 
                                    />
                                </div>

                                <WeekdaysSelector register={register} collaborator={selectedCollaborator} />

                                <div className="c-input">
                                    <label htmlFor="hora_entrada">Hora entrada</label>
                                    <input
                                        id='hora_entrada'
                                        type='time'
                                        name='hora_entrada'
                                        defaultValue={selectedCollaborator.hora_entrada || ''}
                                        {...register("hora_entrada",{required: 'Especifique hora'})} 
                                    />
                                </div>

                                <div className="c-input">
                                    <label htmlFor="hora_salida">Hora salida</label>
                                    <input
                                        id='hora_salida'
                                        type='time'
                                        name='hora_salida'
                                        defaultValue={selectedCollaborator.hora_salida || ''}
                                        {...register("hora_salida",{required: 'Especifique hora'})} 
                                    />
                                </div>

                                <span >
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Close">Cancelar</button>                        
                                    <input class="btn btn-primary" type="submit" value={mode == 'add' ? 'Crear' : 'Actualizar'} />
                              
                                </span>
                           
                            </form>
                        </div>
                    </div>

                    {/* Confirmar */}
                    <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Confirmar eliminado</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                Estás por eliminar DEFINITIVAMENTE el colaborador <strong>{selectedCollaborator.nombre + ' ' + selectedCollaborator.apellidos}</strong>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={(e)=>handleDelete(e)}>Si, eliminar</button>
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancelar</button>
                                
                            </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    );


}

const daysOfWeek = [
    { name: 'Lunes', value: 'lunes' },
    { name: 'Martes', value: 'martes' },
    { name: 'Miércoles', value: 'miercoles' },
    { name: 'Jueves', value: 'jueves' },
    { name: 'Viernes', value: 'viernes' },
    { name: 'Sábado', value: 'sabado' },
    { name: 'Domingo', value: 'domingo' }
];

const WeekdaysSelector = ({ register, collaborator }) => {
    const [selectedDays, setSelectedDays] = useState(collaborator.dias_laborales ? JSON.parse(collaborator.dias_laborales) : []);

    useEffect(() => {
        //console.log( collaborator.dias_laborales)
        setSelectedDays(collaborator.dias_laborales ? JSON.parse(collaborator.dias_laborales) : []);
    }, [collaborator]);

    const handleDayChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
        setSelectedDays([...selectedDays, value]);
        } else {
        setSelectedDays(selectedDays.filter(day => day !== value));
        }
    };

    return (
        <div className="c-input">
            <label>Días Laborales</label>
            <div className="weekdays-selector">
                {daysOfWeek.map((day) => (
                    <div key={day.value}>
                        <input
                            type="checkbox"
                            value={day.value}
                            id={`day-${day.value}`}
                            {...register('dias_laborales')}
                            onChange={handleDayChange}
                            checked={selectedDays.includes(day.value)}
                        />
                        <label htmlFor={`day-${day.value}`}>{day.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminCollaborators;
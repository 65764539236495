import { useEffect } from 'react';
import Logo from '../assets/logo';
import '../styles/navbar.scss';


const NavBar = ({auth, companies, selected, set})=>{

    useEffect(()=>{
        if (companies.length > 0 && !selected) {
            console.log(companies)
            console.log(set)
            if(set != undefined)
                set(companies[0]);  // Selecciona la primera empresa
          }
    },[companies])

    const handleSelectChange = (e) => {
        const selectedCompanyId = parseInt(e.target.value);
        // Busca el objeto de la empresa que tenga el mismo id
        const selectedCompany = companies.find(company => company.id === selectedCompanyId);
        set(selectedCompany);  // Actualiza el estado con el objeto completo de la empresa
       
    };

    
    return(
        <div className='navbar'>
            <div className="navbar-panel">
                <Logo/>
                <div className="c-select">
                    <select name="company" id="company" onChange={handleSelectChange}>
                        {companies.map(company => (
                            <option 
                            key={company.id} 
                            value={company.id} 
                            selected={company.nombre === selected ? true : false}
                            >
                            {company.nombre}
                            </option>
                        ))}
                    </select>
                    <a href="/admin"></a>
                </div>
                
            </div>
            

            <div className="navbar-buttons">
                <h5>{auth.props.children[1]}</h5>
                {
                    auth.props.children[4]
                }
            </div>
        </div>
    )
}

export default NavBar;